<template>
  <no-sidebar-layout v-if="isDataLoaded">
    <v-stepper :value="currentStepId" non-linear>
      <v-stepper-header>
        <template v-for="{ id, name } in steps">
          <v-stepper-step :key="`step-${id}`" :complete="currentStepId > id" :step="id" style="cursor: pointer;" @click="goToStep(id)">
            {{ name }}
          </v-stepper-step>
          <v-divider v-if="+id !== steps.length" :key="`divider-${id}`" />
        </template>
      </v-stepper-header>
    </v-stepper>
    <router-view ref="stepForm" :global-form="form" class="d-flex align-center" />
    <div class="d-flex justify-space-between">
      <v-btn
        v-if="currentStepId >= 2 && currentStepId < steps.length" color="primary"
        outlined dense @click="goToStep(+currentStepId - 1)" v-text="$t('common.return')"
      />
      <v-btn v-if="+currentStepId !== steps.length" color="primary" @click="goToStep(+currentStepId + 1)" v-text="$t('common.next')" />
      <v-btn v-else color="primary" @click="createClubEnrollment()" v-text="$t('club.clubEnrollmentForm.confirmation.sendRequest')" />
    </div>
  </no-sidebar-layout>
</template>
<script>
import { mapGetters } from 'vuex'
import { strToUniqueId } from '@/utils/formatters'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'MainView',
  components: {
    NoSidebarLayout: () => import('@/components/NoSidebarLayout'),
  },
  mixins: [formUtils],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    userComesFromIntro: { type: Boolean, default: false },
  },
  data() {
    return {
      isDataLoaded: false,
      form: {},
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    currentStepId: ({ $route }) => $route.meta.stepId,
    steps() {
      return [
        { id: '1', name: this.$t('club.clubEnrollmentForm.intro.clubData'), isVisited: true },
        { id: '2', name: this.$t('club.clubEnrollmentForm.intro.addTeams'), isVisited: false },
        { id: '3', name: this.$t('club.clubEnrollmentForm.staffData.title'), isVisited: false },
        { id: '4', name: this.$t('club.clubEnrollmentForm.confirmation.title'), isVisited: false },
      ]
    },
  },
  async created() {
    if (!this.userComesFromIntro) this.$router.push({ name: 'club-enrollment-form-intro' })
    const { organizationId, projectId } = this.$route.params
    this.$store.commit('loader/show')
    if (this.project?.id !== projectId) await this.$store.dispatch('project/bind', { organizationId, projectId })
    if (!this.$store.state.competition.dbCohorts.length) await this.$store.dispatch('competition/bindCohorts', { organizationId, projectId })
    if (!this.$store.state.competition.dbRegions.length) await this.$store.dispatch('competition/bindRegions', { organizationId, projectId })
    if (!this.$store.state.competition.dbCategories.length) await this.$store.dispatch('competition/bindCategories', { organizationId, projectId })
    this.$store.commit('loader/hide')
    this.isDataLoaded = true
  },
  methods: {
    goToStep(nextStepId) {
      if (+this.currentStepId === +nextStepId) return
      const nextStepIndex = this.steps.findIndex(step => +step.id === +nextStepId)
      const isCurrentStepComplete = +this.currentStepId !== this.steps.length ? this.$refs.stepForm.isFormValid() : true
      if (!isCurrentStepComplete) { this.scrollToFirstError(); return }
      const isNextStepAvailable = this.steps[nextStepIndex].isVisited || +nextStepId === +this.currentStepId + 1
      if (!isNextStepAvailable) return
      this.form = { ...this.form, ...this.$refs.stepForm.form ?? {} }
      this.steps[nextStepIndex].isVisited = true
      this.$router.push({ name: `club-enrollment-form-step-${nextStepId}` })
    },
    parseForm() {
      return (({ cohorts, compromiseMinPlayers, ...form }) => ({
        ...form,
        website: this.form.website ? this.form.website.toLowerCase() : null,
        federationPlace: this.form.isFederated ? this.form.federationPlace : null,
        id: strToUniqueId(this.form.name),
        teamCountByCohortId: Object.fromEntries(this.form.cohorts.map(({ id, teamCount }) => ([id, teamCount]))),
        staff: this.form.staff.map(({ listId, ...s }) => ({ ...s, email: s.email.toLowerCase() })),
        status: 'pendingApproval',
      }))(this.form)
    },
    async createClubEnrollment() {
      const { organizationId, projectId } = this.$route.params
      this.runAsync(async () => {
        await this.$store.dispatch('clubEnrollmentForm/create', { organizationId, projectId, data: this.parseForm() })
        this.$router.push({ name: 'status', params: { type: 'success', title: 'club.clubEnrollmentForm.success.message' } })
      }, { successAlert: false })
    },
  },
}
</script>
